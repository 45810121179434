<template>
  <div>
    <NoticeComponent />
  </div>
</template>

<script>
import NoticeComponent from '@/components/common/NoticeComponent.vue';

export default {
  components: {
    NoticeComponent,
  },
};
</script>

<style></style>
