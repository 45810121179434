<template>
  <div class="row content-wrapper">
    <h1 class="notice-title">공지사항</h1>
    <LoadingSpinner v-if="isLoading" />
    <template v-else>
      <div>
        <div
          class="notice-item"
          v-for="article in noticeArticles"
          :key="article.id"
        >
          <h3
            class="notice-item-title"
            @click="toggleContent"
            :id="`title_${article.id}`"
          >
            {{ article.title }}
          </h3>
          <div class="notice-item-date">
            {{ article.created_at | moment('YYYY.MM.DD') }}
          </div>
          <div
            :id="`content_${article.id}`"
            class="notice-item-content"
            v-html="article.description"
            ref="noticeItemContent"
          ></div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import LoadingSpinner from '@/components/common/LoadingSpinner.vue';

export default {
  data() {
    return {
      isLoading: false,
    };
  },
  components: {
    LoadingSpinner,
  },
  computed: {
    ...mapState(['noticeArticles']),
  },
  created() {
    this.fetchData();
  },
  methods: {
    ...mapActions(['FETCH_NOTICES']),
    fetchData() {
      this.isLoading = true;
      this.FETCH_NOTICES();
      this.isLoading = false;
    },
    toggleContent(e) {
      let parent = e.currentTarget.parentElement;
      // let items = document.querySelectorAll('.notice-item-content');
      let child = parent.querySelector('.notice-item-content');
      // for (let i = 0; i < items.length; i++) {
      //   items[i].classList.remove('active-item');
      // }
      child.classList.toggle('active-item');
    },
  },
};
</script>

<style scoped>
.notice-title {
  margin-bottom: 15px;
  font-size: 30px;
  color: #333;
}
.notice-item {
  padding: 30px 0;
  border-bottom: 0.5px solid #c8c8c8;
}
.notice-item-title {
  padding-bottom: 10px;
  font-size: 22px;
  font-weight: 500;
  color: #333;
  cursor: pointer;
}
.notice-item-date {
  font-size: 18px;
  color: #bababa;
}
.notice-item-content {
  display: none;
  padding-top: 45px;
  font-size: 20px;
  color: #333;
  line-height: 34px;
}
.notice-item-content.active-item {
  display: block;
}
</style>
